// We define the mixins for two major breakpoints mobile and tablet

 @mixin devices ($breakpoint) { //the name of the mixin is devices

  @if $breakpoint == moniter {
    @media (min-width: 991px) and (max-width: 1024px) {
      @content;
    }
  }
  @if $breakpoint == laptop {
    @media (min-width: 991px) and (max-width: 1440px) {
      @content;
    }
  }
    @if $breakpoint == tablet {
    @media (min-width: 768px) and (max-width: 990.9px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: 767.9px) {
     @content;
    }
  }
}

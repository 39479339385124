// @import url("https://fonts.cdnfonts.com/css/giveny-free");
// @import url("https://fonts.cdnfonts.com/css/open-sans");

// $heading-font-family: "Giveny Free", sans-serif !important;
// $body-font-family: "Open Sans", sans-serif !important;
$body-bg-color: #fff6ee;
$primary_color: #2497c4 !default;
$secondary_color: #c2edcf !default;
$secondary_lite: #badfe7 !default;
$tertiary_color: #378087 !default;
$auqa_color: #e2f7ff;
$orange_color: #f28334;
$color_black: #1d1d1d;
$color_gray: #616161;
$color_dark: #275155 !default;
$thead_color: #d9d9d9;
$dark_bg: #3b4248;
$sidenav-bg: #f2eee2;
$dash-icon-active_color: #6fb3b8;
$theme-colors: (
  "primary": $primary_color,
  "secondary": $secondary_color,
);
$info-color: #3366cc;
$success-color: #27ae60;
$warning-color: #e2b93b;
$error-color: #dd3030;
$link-color: #3559bb;
$bg-white: #ffff;
$bg-light: #eee;
$border-color: #e5e5e5;
$text-black: #000000;
$text-grey: #333333;
$text-white: #ffff;
$text-blue: #2d308d;
$text-orange: #f28334;
$text-gray: rgba(255, 255, 255, 0.7);
$input-placeholder-color: #afafaf;
$box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.3);
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;
$headings-font-weight: $font-weight-bold;
$base-text-color: #333333;
$body-color: $base-text-color;
$text-color: $base-text-color;
$headings-color: $base-text-color;
$border-radius: 10px;
$link-color: $base-text-color;
$link-hover-color: $primary_color;
$link-hover-decoration: none;
$input-btn-focus-box-shadow: none;
$font-size-normal: 14px;
$font-size-medium: 16px;
$font-size-base: 1px;
$h1-font-size: 52 * $font-size-base;
$h2-font-size: 40 * $font-size-base;
$h3-font-size: 36 * $font-size-base;
$h4-font-size: 30 * $font-size-base;
$h5-font-size: 20 * $font-size-base;
$h6-font-size: 16 * $font-size-base;

// Input and Buttons
$border-radius: 7px;
$button-radius: 5px;
$input-border-color: #406467;
$input-border-radius: 7px;
$input-btn-padding-y: 0.625rem !default;
$input-btn-padding-x: 0.9375rem !default;
$input-btn-line-height: 1.25 !default;
$btn-border-radius: $button-radius;
$btn-border-radius-lg: $button-radius;
$btn-border-radius-sm: $button-radius;
$btn-font-weight: $font-weight-bold;
$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-outline-focus-box-shadow: none;
$btn-focus-width: 0;

@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-family: $heading-font-family;
// }

// body {
//   font-family: $body-font-family !important;
// }

h1 {
  font-size: $h1-font-size !important;
  line-height: 45px !important;
}
h2 {
  font-size: $h2-font-size !important;
}
h3 {
  font-size: $h3-font-size !important;
}
h4 {
  font-size: $h4-font-size !important;
}
h5 {
  font-size: $h5-font-size !important;
}
h6 {
  font-size: $h6-font-size !important;
}

html {
  scroll-behavior: smooth;
}

.row {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}
.empty-space-40 {
  height: 40px;
}
.empty-space-30 {
  height: 30px;
}
.empty-space-20 {
  height: 20px;
}
.empty-space-10 {
  height: 10px;
}
.clear {
  clear: both;
  overflow: hidden;
  height: 0px;
  font-size: 0px;
  display: block;
}
.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.swiper-pagination-bullet-active {
  background-color: $primary_color !important;
}
.form-control,
.form-select,
.input-group-text {
  border-radius: 0 !important;
}

// Heading Underline
.h1,
.h2,
.h3 {
  &:before {
    content: "";
    position: absolute;
    width: 60px;
    height: 1px;
    margin-top: 45px;
    left: 50%;
    margin-left: -25px;
    background-color: $primary_color;
  }
}

input:focus {
  outline: none;
  border: none;
  background: none;
}

// Cards Styling
.card {
  transition: 0.5s;
  cursor: pointer;
  a {
    text-decoration: none;
    color: $text-color;
  }
  .card-title {
    cursor: pointer;
    margin-left: 8px;
  }
  ul.card-text {
    list-style-type: none;
    margin-top: 5px;
    margin-bottom: 40px;
    li:before {
      content: "";
      position: absolute;
      left: 0;
      margin-top: 12px;
      margin-left: 8px;
      width: 15px;
      height: 1.5px;
      background-color: $primary_color;
    }
  }
  &:hover {
    transform: scale(1.05);
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    a {
      color: $text-color;
    }
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale3d(0, 0, 1);
    transition: transform 0.3s ease-out 0s;
    background: rgba(255, 255, 255, 0.1);
    content: "";
    pointer-events: none;
  }
  &::before {
    transform-origin: left top;
  }
  &::after {
    transform-origin: right bottom;
  }
  &:hover::before,
  &:hover::after,
  &:focus::before,
  &:focus::after {
    transform: scale3d(1, 1, 1);
  }
}
//  Parallax Effect
.parallax {
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 450px;
  position: relative;

  .banner {
    top: 50%;
    position: relative;
    text-align: center;
    align-items: center;
  }
}
// End of parallax
.bg-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

// Buttons Styling

.button-save {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  button {
    background-color: $orange_color;
    color: white;
    text-transform: capitalize;
    font-weight: $font-weight-normal;
  }
}

.button-add-more {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 48% !important;
  button {
    color: $orange_color;
    font-weight: $font-weight-normal;
    border: 1px solid $orange_color;
    text-transform: capitalize;
  }
}

.button-primary {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: $primary_color;
  color: $text-white !important;
  border: 1px solid $primary_color;
  font-size: 14px;
  font-weight: 500;
  border-radius: $button-radius;
  text-decoration: none !important;
  &:hover {
    background-color: $text-white !important;
    color: $color_gray !important;
  }
  .mat-icon {
    font-size: 20px;
    margin-right: 2px;
  }
}

.button-secondary {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: $secondary_color;
  color: $color_gray !important;
  border: 1px solid $primary_color;
  font-size: 14px;
  font-weight: 500;
  border-radius: $button-radius;
  text-decoration: none !important;
  &:hover {
    background-color: $primary_color !important;
    color: $text-white !important;
  }
  .mat-icon {
    font-size: 20px;
    margin-right: 2px;
  }
}
.button-outline {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: #ffff;
  border: 1px solid $primary_color;
  color: $color_gray;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  border-radius: $button-radius;
  &:hover {
    background-color: $primary_color;
    color: $text-white;
  }
  .mat-icon {
    font-size: 20px;
    margin-right: 2px;
  }
}

.button-orange {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: $orange_color;
  color: $text-white !important;
  border: 1px solid $orange_color;
  font-size: 14px;
  font-weight: 500;
  border-radius: $button-radius;
  text-decoration: none !important;
  &:hover {
    background-color: $text-white !important;
    color: $orange_color !important;
  }
  .mat-icon {
    font-size: 20px;
    margin-right: 2px;
  }
}

.button-danger {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: $error-color;
  color: $text-white !important;
  border: 1px solid $error-color;
  font-size: 14px;
  font-weight: 500;
  border-radius: $button-radius;
  text-decoration: none !important;
  &:hover {
    background-color: $text-white !important;
    color: $error-color !important;
  }
  .mat-icon {
    font-size: 20px;
    margin-right: 2px;
  }
}
.info-btn-light {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $orange_color;
    border: 1px solid #f28334;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
    img {
      margin-left: 12px;
    }
  }
}
.info-btn-dark {
  justify-content: flex-end;
  align-items: center;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $orange_color;
    color: #fff;
    padding: 7px 8px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
    mat-icon,
    img {
      margin-left: 12px;
    }
  }
}

.button-outline_orange {
  display: flex;
  align-items: center;
  padding: 5px 12px;
  background-color: #ffff;
  border: 1px solid $orange_color !important;
  color: $text-color;
  font-size: 12px;
  font-weight: 500;
  // text-transform: uppercase;
  text-decoration: none;
  border-radius: $button-radius;
  &:hover {
    background-color: $orange_color;
    color: $text-white;
    .mat-icon {
      color: $text-white;
    }
  }
}

.primary-color {
  color: $primary_color;
}
.star-mark,
.error-color {
  color: $error-color;
}

.g-recaptcha {
  display: block;
  margin: 0 auto;
  width: 100%;
}

//Scrollbar styling from chrome browser

// ::-webkit-scrollbar {
//   width: 5px;
// }
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }
// ::-webkit-scrollbar-thumb {
//   background: $secondary_color;
// }

//Dashboard pages styling
.dash-search {
  .search {
    align-items: normal !important;
  }
  input[type="text"] {
    font-size: 11px !important;
    height: 32px !important;
    padding: 2px 8px !important;
  }
  button {
    min-height: 32px !important;
    padding: 0px 6px !important;
    span {
      color: $text-white !important;
      font-size: 9px;
    }
  }
}

//Table Style
.table_wrapper {
  background: $text-white;
  border-radius: 7px;
  padding: 20px 5px;
  th,
  td {
    text-align: center !important;
  }
}

.icons p-panel {
  z-index: 999;
  position: absolute;
  top: 12%;
  right: 0;
}
.category {
  &:hover {
    svg polygon {
      fill: $primary_color !important;
    }
  }
}

.carousel_wrapper {
  margin: 0 2%;
  background: $text-white;
  padding: 10px 15px 0;
  box-shadow: $box-shadow;
  border-radius: $button-radius;
  position: relative;
  z-index: 99;
  top: -13%;
  .heading {
    font-size: 14px !important;
  }
  .p-carousel-item {
    img {
      width: 75px;
      height: 75px;
      border-radius: 50%;
    }
    .title {
      font-size: 12px;
      font-weight: 500;
    }
  }
}

// Overwrite Primeng Style
.p-carousel-indicators {
  display: none !important;
}
// Overwrite NGX Component Style
ngx-intl-tel-input {
  .separate-dial-code {
    width: 100%;
  }
  .selected-dial-code {
    font-size: 13px !important;
  }
  .country-dropdown {
    width: 270px !important;
  }
  input#phone {
    width: 100%;
    height: 40px;
    border-radius: $input-border-radius !important;
    border-color: $primary_color !important;
    font-size: 13px !important;
    // margin-top: 7px;

    &:focus-visible {
      outline: unset !important;
      border: 2px solid #2497c4;
    }
  }
  input#country-search-box {
    &:focus-visible {
      outline: unset !important;
    }
  }
}

.offcanvas-body {
  .ngx-otp-input-container {
    justify-content: center !important;
  }
  .ngx-otp-input {
    width: 40px !important;
    height: 40px !important;
    border: 1px solid $thead_color !important;
  }
}

.right-featured-image {
  .ngx-file-drop__drop-zone,
  .ngx-file-drop__content {
    height: 500px !important;
    width: auto;
  }
}
#user-profile_configuration .ngx-file-drop__drop-zone {
  height: 100%;
  margin: auto;
  border: 2px dotted $orange_color !important;
  border-radius: 20px !important;
  .ngx-file-drop__content {
    height: 100%;
  }
}

.ngx-file-drop__drop-zone {
  height: 100px;
  margin: auto;
  border: 2px dotted $orange_color !important;
  border-radius: 20px !important;
}
.ngx-file-drop__content {
  color: $color_gray !important;
  font-size: 12px !important;
  p {
    font-size: 10px !important;
  }
  .browse-files {
    color: $orange_color !important;
    font-weight: 500 !important;
    cursor: pointer;
  }
}

// Overwrite Material UI Style

.mat-checkbox-label {
  font-weight: 400 !important;
}
.mat-icon {
  overflow: visible !important;
}

.mat-select-arrow-wrapper {
  align-items: flex-end !important;
}
.mat-select-arrow {
  color: $orange_color;
}
.mat-input-element {
  caret-color: #000 !important;
}

#main-header,
#dash-header {
  .details {
    mat-select {
      .mat-select-placeholder {
        color: #271a8c !important;
        font-size: 16px !important;
        font-weight: $font-weight-medium;
      }
      .mat-select-min-line {
        color: #271a8c !important;
        font-size: 16px !important;
        font-weight: $font-weight-medium;
      }
    }
  }
  mat-select {
    .mat-select-placeholder {
      color: #fff;
      font-size: $font-size-normal;
      font-weight: $font-weight-medium;
    }
    .mat-select-min-line {
      font-size: $font-size-normal;
      font-weight: $font-weight-medium;
    }
    .mat-select-arrow {
      color: #fff !important;
    }
  }
  .header-middle {
    .searching {
      mat-select {
        .mat-select-placeholder {
          color: #000 !important;
          font-size: $font-size-normal;
          font-weight: $font-weight-medium;
        }
        .mat-select-min-line {
          color: #000 !important;
          font-size: $font-size-normal;
          font-weight: $font-weight-medium;
        }
        .mat-select-arrow {
          color: #000 !important;
        }
      }
    }
  }
  mat-form-field {
    width: 60%;
    .mat-form-field-outline-start,
    .mat-form-field-outline,
    .mat-form-field-outline-end {
      border-color: #304a6f !important;
      color: #304a6f !important;
    }
    .mat-form-field-appearance-outline {
      border-color: #304a6f !important;
      color: #304a6f !important;
    }
    .mat-form-field-outline {
      background: #ffffff;
    }
    .mat-form-field-infix {
      display: flex;
      align-items: center;
      padding: 2px !important;
    }
    fa-icon {
      color: #000;
      font-size: 15px;
    }
    input {
      margin-left: 10px;
      ::placeholder {
        font-size: $font-size-normal;
        font-weight: $font-weight-medium;
      }
    }
  }
}

#dashboard,
#reports,
#lead-data,
#company-report,
#bbi-report {
  mat-form-field {
    width: 100%;
    .mat-form-field-outline-start,
    .mat-form-field-outline,
    .mat-form-field-outline-end {
      border-color: #304a6f !important;
      color: #304a6f !important;
    }
    .mat-form-field-appearance-outline {
      border-color: #304a6f !important;
      color: #304a6f !important;
    }
    .mat-form-field-outline {
      background: #ffffff;
    }
    .mat-form-field-infix {
      display: flex;
      align-items: center;
      padding: 5px 0px !important;
    }
    .mat-select-arrow-wrapper {
      height: 16px;
      flex-shrink: 0;
      display: inline-flex;
      align-items: flex-end;
    }
  }
  .mat-tab-labels {
    width: 80%;
  }
  .pie-chart,
  .line-chart {
    .p-element {
      canvas {
        height: 375px !important;
      }
    }
  }

  #bbi-admin {
    .mat-tab-labels {
      width: 35%;
    }
  }
  .mat-tab-label {
    padding: 0px !important;
  }
  .mat-tab-label-active {
    color: #f28334;
  }
  .mat-ink-bar {
    background-color: #f28334;
    width: 220px !important;
  }
  .mat-tab-body-wrapper {
    border: none !important;
  }
  .mat-tab-header {
    border-bottom: none !important;
    margin: 25px 0px;
  }
  .mat-tab-group[mat-stretch-tabs] > .mat-tab-header .mat-tab-label {
    justify-content: flex-start;
  }
  .mat-table {
    width: 100%;
    thead {
      background: none !important;
      tr {
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #d9d9d9 !important;
        border: 0.1em solid #000;
        border-radius: 5px;
        width: 100%;
        th {
          background: none;
        }
        th.mat-header-cell {
          border-bottom-style: hidden;
        }
      }
    }
    tbody {
      tr {
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f5f9ff80;
        border: 0.1em solid #278be5;
        border-radius: 5px;
        width: 100%;
        td {
          .view {
            margin: 0px 10px;
          }
        }
        td.mat-cell {
          border-bottom-style: hidden;
        }
      }
      tr:nth-child(even) {
        background-color: #fff;
      }
    }
  }
  .table-performance {
    tbody {
      tr {
        td {
          .vector {
            margin: 0px 30px !important;
          }
        }
      }
    }
  }
}

#order-buyer,
#order-seller {
  .mat-tab-labels {
    width: 55%;
  }
  .mat-tab-label {
    padding: 0px !important;
  }
  .mat-tab-label-active {
    color: #f28334;
  }
  .mat-ink-bar {
    background-color: #f28334;
  }
  .mat-tab-body-wrapper {
    border: none !important;
  }
  .mat-tab-header {
    border-bottom: none !important;
    margin: 25px 0px;
  }
  .mat-tab-group[mat-stretch-tabs] > .mat-tab-header .mat-tab-label {
    justify-content: flex-start;
  }
  .mat-tab-label-content {
    font-size: 22px;
  }
}

#order-seller {
  .ngx-file-drop__drop-zone {
    height: 350px !important;
    background: #f2833404;
  }
  .ngx-file-drop__content {
    height: 350px !important;
  }
}
#orders-dialog {
  .mat-radio-label {
    display: flex;
    align-items: flex-start;
  }
}

#dash-footer {
  .main-footer {
    background-color: $secondary_color;
    margin-top: 0px;
    h6,
    a {
      color: $color_gray;
    }
  }
}

#landing-page {
  .banner-top {
    .owl-stage-outer {
      img {
        height: 500px;
        object-fit: fill;
      }
    }
    .owl-nav {
      position: absolute;
      top: 140px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      .owl-prev,
      .owl-next {
        color: #d3c4c4;
        font-size: 70px;
        background: none !important;
        cursor: pointer;
      }
    }
    .owl-dots {
      position: absolute;
      left: 50%;
      top: 94%;
    }
  }
  .categories-list::-webkit-scrollbar {
    display: none;
  }

  #carousel-product-card {
    .owl-nav {
      position: absolute;
      top: 120px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 0px;
      .owl-prev,
      .owl-next {
        margin: 0;
        color: $orange_color;
        font-size: 38px;
        background: #fff;
        border-radius: 50%;
        cursor: pointer;
        width: 45px;
        height: 45px;
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
    .owl-item {
      cursor: pointer;
      text-transform: capitalize;

      img {
        cursor: pointer;
        width: 100%;
        height: 250px;
        object-fit: fill;
        border-radius: 6px;
        @include devices(mobile) {
          height: 150px;
        }
      }
      img:hover {
        border: 1px solid $orange_color;
      }
      span {
        font-size: $font-size-medium;
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  #product-card,
  #carousel-product-card {
    .mat-card {
      margin: 10px 0px !important;

      .title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  #icon-listing {
    .owl-stage-outer {
      margin-top: 30px;
    }
    .owl-nav {
      .owl-prev {
        position: absolute;
        top: 83px;
        left: 0;
        color: #d3c4c4;
        font-size: 70px;
        background: none !important;
        cursor: pointer;
      }
      .owl-prev:hover {
        color: $orange_color;
      }
      .owl-next {
        position: absolute;
        top: 83px;
        right: 0;
        color: #d3c4c4;
        font-size: 70px;
        background: none !important;
        cursor: pointer;
      }
      .owl-next:hover {
        color: $orange_color;
      }
    }
    .owl-item {
      justify-content: center;
      display: grid;
      text-align: center;
      .icon-item {
        cursor: pointer;
        align-items: center;
        display: grid;
        padding: 14px;
        width: 150px;
        height: 150px;
        border: 2px solid #eaeaea;
        border-radius: 50%;
        span {
          justify-content: center;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          img {
            width: 40px;
            height: auto;
          }
        }
        .line-break {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .icon-item:hover {
        border-color: $orange_color !important;
      }
    }
  }
  .mat-card {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 12px !important;
    .mat-card-header-text {
      margin: 0 !important;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    .image {
      justify-content: space-between;
      .col-md-6 {
        width: 48%;
        img {
          cursor: pointer;
          width: 100%;
          height: 180px;
          object-fit: fill;
          border-radius: 6px;
        }
        img:hover {
          border: 1px solid $orange_color;
        }
      }
      .col-md-4 {
        width: 32%;
      }
    }
    .mat-card-content {
      text-transform: capitalize;
      padding-top: 10px;
    }
    .mat-card-image {
      cursor: pointer;
      margin: 0 !important;
      width: 150px;
      height: 150px;
      float: right;
      object-fit: fill;
    }
    .mat-card-image:hover {
      opacity: 0.8; /* Reduce opacity on hover */
      transition: opacity 0.3s ease-in;
    }
    .mat-card-actions {
      margin: 0;
      padding: 0;
    }
    a {
      color: #2497c4 !important;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .category-products {
    .mat-card {
      box-shadow: none !important;
      position: relative;
      box-sizing: border-box;
      border: 1px solid #e6ecf2;
      border-radius: 0;
      .mat-card-title {
        text-transform: capitalize;
        font-size: 15px;
        font-weight: 400;
      }
    }
  }
}
#view-product {
  #similar-product-view {
    .owl-nav {
      position: absolute;
      top: 120px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 0px;
      .owl-prev,
      .owl-next {
        margin: 0;
        color: $orange_color;
        font-size: 38px;
        background: #fff;
        border-radius: 50%;
        cursor: pointer;
        width: 45px;
        height: 45px;
        align-items: center;
        display: flex;
        justify-content: center;
        @include devices(mobile) {
          background: none !important;
        }
      }
    }
    .owl-item {
      cursor: pointer;
      text-transform: capitalize;
      .mat-card {
        width: 275px;
        min-height: 420px;
        @include devices(mobile) {
          padding-top: 5px !important;
          min-height: 305px;
        }
      }
      img {
        cursor: pointer;

        @include devices(mobile) {
          height: 150px;
        }
      }
      img:hover {
        border: 1px solid $orange_color;
      }
      span {
        font-size: 14px;
        display: flex;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        .currency-code {
          margin-right: 5px;
        }
      }
    }
  }
}

.trending-searches_mobile,
.related-searches {
  .mat-chip-list-wrapper {
    flex-wrap: nowrap !important;
  }
}

.owl-item {
  img {
    width: none !important;
    height: auto;
  }
  .chips {
    .mat-chip.mat-standard-chip {
      background-color: #fff !important;
      border: 1px solid $info-color;
    }
  }
}

.mat-form-field-infix {
  display: flex;
  align-items: center;
  mat-icon {
    background: #f5714b1a;
    border-radius: 50%;
    padding: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    margin-right: 20px;
  }
  input {
    color: #000;
    .ng-reflect-placeholder {
      font-size: $font-size-normal;
      font-weight: $font-weight-medium;
    }
  }
}
.mat-form-field-infix {
  padding: 25px 0px;
  display: flex;
  align-items: center;
  input {
    .mat-focused .placeholder {
      color: #00d318;
    }
  }
  .mat-form-field-label-wrapper {
    .mat-form-field-label {
      span {
        color: #000;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

.change-currency {
  .mat-form-field-infix {
    width: auto !important;
  }
  .mat-form-field-underline {
    position: static !important;
    width: 35px !important;
    .mat-form-field-ripple {
      height: unset !important;
    }
  }
}

#postRequestDialog {
  .mat-form-field-infix {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .mat-select {
      width: 75px !important;
    }
    .tradingConditions {
      width: 100% !important;
    }
    .mat-input-element {
      caret-color: #000 !important;
    }
  }
}

#bbi-footer {
  .mat-form-field-outline-start,
  .mat-form-field-outline,
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    color: #000 !important;
    border-color: #000 !important;
    padding: 0% !important;
  }
}
#sign-up,
#sign-in {
  .mat-form-field-infix {
    padding: 0.5em 0 !important;
    display: flex;
    align-items: center;
  }
}
.gst-verify,
.mobile-verify {
  width: 100%;
  .mat-form-field-infix {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

#chat_support {
  mat-form-field {
    width: 100%;
    .mood {
      font-size: 21px;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333333;
    }
    .link {
      margin: 0px 20px;
      font-size: 21px;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(133deg);
      color: #333333;
    }
    .mat-form-field-infix {
      display: flex;
      align-items: center;
    }
  }
  .mat-form-field-infix {
    fa-icon {
      color: #f28334;
    }
    input {
      align-items: center;
      margin-left: 10px;
      display: flex;
      justify-content: center;
    }
    img {
      background: #f28334;
      padding: 7px;
      border-radius: 50%;
    }
  }
}
#business_profile {
  mat-form-field {
    .mat-form-field-infix {
      display: flex;
      align-items: center;
    }
  }
  .mat-form-field-infix {
    fa-icon {
      color: #f28334;
    }
    input {
      align-items: center;
      margin-left: 10px;
      display: flex;
      justify-content: center;
    }
    img {
      background: #f28334;
      padding: 7px;
      border-radius: 50%;
    }
  }
}
#leads,
#seller-product-list {
  mat-tab-group {
    mat-tab-header {
      button {
        display: none !important;
      }
    }
    .mat-tab-body {
      background: #fff;
    }
  }
  mat-form-field {
    width: 100%;
    .mat-form-field-infix {
      display: flex;
    }
    fa-icon {
      color: $orange_color;
      font-size: 15px;
    }
    input {
      margin-left: 10px;
    }
  }
  .mat-ripple {
    justify-content: flex-start;
  }
  .mat-tab-label-content {
    font-size: 15px;
    color: #000;
    font-weight: 400;
    .mat-tab-label-active {
      color: $orange_color;
    }
  }
  .mat-tab-label {
    padding: 0 10px !important;
  }
  .mat-ink-bar {
    background: $orange_color;
  }

  .mat-tab-label-active {
    color: $orange_color;
    .mat-tab-label-content {
      color: $orange_color;
    }
  }
}

#information {
  padding: 10px 0px 10px;
  .mat-ripple {
    background-color: #388087;
    padding: 40px;
    border-radius: 20px 20px 0px 0px;
    width: 46%;
  }
  .mat-tab-label-content {
    font-weight: $headings-font-weight;
    color: white;
    font-size: 30px;
  }
  .mat-tab-body-content {
    background-color: #d9dded;
    display: flex;
    padding: 20px 0px 20px;
  }
}

mat-select-country {
  .mat-form-field {
    width: 100% !important;
  }
}
mat-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

//MatDialog Sytling

.mat-dialog-container,
.mat-dialog-content {
  overflow: unset !important;
}
.mat-dialog-container {
  mat-form-field {
    width: 100%;
  }
  input {
    font-size: 12px;
    ::placeholder {
      font-size: 10px;
      padding-bottom: 5px !important;
    }
  }

  .desc {
    display: grid;
    .card-title {
      margin: 0 !important;
      font-size: 12px;
      font-weight: 500;
    }
    .card-text {
      font-size: 11px;
      padding: 5px 0;
    }
  }
  .scope,
  .status {
    padding: 20px 0;
    font-size: 12px;
    font-weight: 500;
    mat-radio-group {
      display: flex;
      justify-content: space-between;
    }
  }

  .mat-dialog-actions {
    display: flex;
    justify-content: flex-end;
    a {
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
    }

    .cancel {
      color: $error-color;
      padding-right: 0 !important;
    }
  }
}
#sendQuoteDialog .mat-dialog-content {
  width: 600px;
  mat-form-field {
    width: 450px;
  }
  .mat-radio-label-content {
    font-size: 15px;
    font-weight: 500;
  }
  .mat-input-element:disabled {
    color: $color_gray !important;
  }
}

#deleteConfirmation .mat-dialog-content {
  width: 600px;
}

#productCatalogue .mat-dialog-content {
  width: 750px;
  overflow: auto !important;
  iframe {
    width: 100% !important;
    height: 500px;
  }
}

mat-dialog-container {
  #addCategory,
  #addScreen,
  #addRole {
    min-width: 450px;
  }
}

mat-dialog-container {
  #addUser,
  #addProduct {
    width: 600px;
    mat-form-field {
      width: 100%;
      max-width: 100% !important;
      .mat-icon-button {
        height: unset !important;
        font-size: small !important;
      }
      .mat-select-value {
        font-size: 12px;
      }
    }
    .product-form-field {
      width: 90% !important;
    }
    .mat-dialog-title {
      margin: 0 0 18px !important;
    }
    .mat-form-field-infix {
      padding: 0.5em 0 !important;
    }
  }
  .roles {
    mat-form-field {
      width: 100% !important;
    }
  }
}
#addUser {
  .mat-select-panel-wrap {
    margin-left: 40px !important;
  }
}

mat-dialog-container #addAddressDialog {
  mat-dialog-content {
    width: 650px;
    .row {
      justify-content: space-between;
      mat-form-field {
        width: 298px;
        padding: 15px 0px;

        .tradingConditions {
          text-align: start;
          padding: 0;
        }
      }
      .col-md-12 {
        mat-form-field {
          width: 100%;
        }
      }
    }
    .mat-icon-button {
      height: unset !important;
      font-size: small !important;
    }
    .mat-select-value {
      font-size: 12px;
    }
  }
  .mat-form-field-infix {
    display: flex;
    align-items: center;
    mat-icon {
      font-size: 18px;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      color: $error-color;
    }
  }
  .status {
    padding: 10px 6px;
  }
  .mat-dialog-title {
    margin: 0;
    padding: 0;
  }

  input {
    &:focus {
      box-shadow: none !important;
    }
  }
  .gst-verify .mat-form-field-infix {
    padding-top: 0;
  }
}

#product-desc,
#edit-leads {
  .mat-form-field-infix {
    display: flex;
    align-items: center;
    mat-icon {
      font-size: 18px;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      color: $error-color;
    }
    mat-select {
      width: 90px;
    }
  }
  .details-top {
    mat-select {
      width: 100%;
    }
  }
}

#user-profile_configuration {
  .mat-form-field-infix {
    padding: 8px !important;
    display: flex;
    align-items: center;
    mat-icon {
      font-size: 18px;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      color: #f28334;
    }
  }
}

.mat-select-panel .mat-option-text {
  font-size: 12px !important;
}
.actions {
  display: flex;
  justify-content: flex-end;
  a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
  }
  .cancel {
    color: $error-color;
    padding-right: 0 !important;
  }
}

.mat-radio-button {
  .mat-radio-outer-circle {
    border-color: $primary_color !important;
  }
  .mat-radio-inner-circle {
    background-color: $primary_color !important;
  }
}
.mat-radio-disabled {
  .mat-radio-outer-circle {
    border-color: $color_gray !important;
  }
  .mat-radio-inner-circle {
    background-color: $color_gray !important;
  }
}

.mat-menu-panel {
  // height: 650px;
  min-width: 255px !important;
  &::-webkit-scrollbar {
    width: 0.5em;
    background-color: #f5f5f5;
  }
}

.mat-autocomplete-panel {
  .mat-option-text {
    font-size: 12px !important;
  }
}

//Mat Table Styling
table {
  thead {
    background-color: $thead_color !important;
    .mat-checkbox-frame {
      border-color: $text-black !important;
    }
    .mat-header-cell {
      color: $text-black !important;
      text-transform: uppercase !important;
    }
  }
  tbody .mat-cell {
    font-size: 12px !important;
    padding-top: 12px;
  }
  .mat-slide-toggle-bar {
    height: 8px !important;
    width: 19px !important;
    // background-color: #F28334!important;
  }

  .mat-slide-toggle-thumb {
    height: 11px !important;
    width: 11px !important;
    // background-color: #fff!important;
  }
  .mat-slide-toggle-thumb-container {
    width: 12px !important;
    height: 12px !important;
    top: -2px !important;
  }
  th:first-child {
    border-radius: 7px 0 0 0 !important;
  }
  th:last-child {
    border-radius: 0 7px 0 0 !important;
  }
}
.mat-column-action .ng-fa-icon {
  cursor: pointer !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $thead_color !important;
  background-color: #fff !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $secondary_color !important;
  background-color: #f28334 !important;
}

.mat-checkbox-checked,
.mat-checkbox-indeterminate {
  .mat-checkbox-background {
    background-color: $primary_color !important;
  }
}
.mat-pseudo-checkbox-checked,
.mat-checkbox-indeterminate {
  background-color: $primary_color !important;
}

.mat-select-panel-wrap {
  flex-basis: unset !important;
  overflow: hidden;
  min-width: 200px;
  margin-left: 5px;
  .mat-select-search-inner {
    width: 200px !important;
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-flex {
    background: transparent !important;
    border-radius: $input-border-radius !important;
    border-color: $primary_color !important;
  }
  .mat-form-field-infix {
    font-size: 14px !important;
    textarea {
      font-size: 12px !important;
    }
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline,
  .mat-form-field-outline-end {
    border-color: $primary_color !important;
    color: $primary_color !important;
  }
}
#addAddressDialog .mat-form-field-flex {
  height: 45px !important;
}

.card-body,
.mat-dialog-content {
  .mat-form-field-infix {
    width: 100% !important;
  }
  .mat-form-field-outline {
    color: $base-text-color !important;
  }
  .mat-form-field-outline-start,
  .mat-form-field-outline,
  .mat-form-field-outline-end {
    border-color: $input-border-color !important;
  }
  .mat-input-element:disabled {
    color: #222 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
}

.top-bar_actions {
  background: $text-white;
  padding: 20px 5px;
  margin: 20px 0;
  border-radius: 7px;
  .page-title {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  .title-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .action {
    display: flex;
    justify-content: flex-end;
  }

  .mat-slide-toggle {
    margin-top: 5px !important;
  }
}

.search-bar {
  .mat-form-field-flex {
    padding-top: 0 !important;
  }
  .mat-form-field-infix {
    font-size: 12px;
    color: $base-text-color !important;
    display: flex;
    position: relative;
    width: 300px;
    background: $text-white;
    padding: 5px 10px 8px;
    box-shadow: 0px 2px 0px $primary_color;
  }
  .mat-form-field-underline {
    position: static !important;
    .mat-form-field-ripple {
      height: unset !important;
    }
  }
}

.dropdown {
  .mat-form-field-flex {
    padding-top: 0 !important;
  }
  .mat-form-field-infix {
    font-size: 12px !important;
    color: $base-text-color !important;
    display: flex;
    position: relative;
    width: 300px;
  }

  .mat-form-field-underline {
    position: static !important;
    .mat-form-field-ripple {
      height: unset !important;
    }
  }
}

#main-header .header-middle {
  .mat-select-panel-wrap {
    min-width: 100px !important;
  }
  .mat-form-field-underline {
    position: static !important;
    .mat-form-field-ripple {
      height: unset !important;
    }
  }
}
.screen-role-map {
  .add-new,
  .bulk-action {
    display: none !important;
  }
}
.seller-product-list {
  .bulk-action,
  .search-bar {
    display: none !important;
  }
}
.mat-form-field-appearance-outline {
  .mat-form-field-outline,
  .mat-form-field-outline-thick {
    border-color: $secondary_color !important;
  }
}
.mat-error {
  font-size: 11px !important;
  padding: 0 5px !important;
}

//Paginator Styling

.mat-paginator-page-size-select .mat-form-field-underline {
  position: initial;
}

#seller-product-list,
#product-page,
#order-buyer,
#order-seller {
  .mat-form-field-subscript-wrapper {
    position: unset !important;
  }
  .ngx-pagination {
    float: right !important;
    li {
      margin-left: 5px;
      border: 1px solid $color_black;
      border-radius: 50% !important;
      a {
        text-decoration: none !important;
      }
    }
    li.pagination-previous,
    li.pagination-next {
      border: none !important;
    }
    li.current {
      border-radius: 50% !important;
      background-color: $color_black !important;
    }
    a:hover {
      background: unset !important;
    }
  }
}
// Dialog styling //

.dialog-box {
  width: 100%;
  .header-content {
    width: 100%;
    .col-md-10 {
      display: flex;
      justify-content: center;
    }
  }
  .col-md-1 {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  h5 {
    font-weight: $font-weight-medium;
    $h5-font-size: 20;
    text-transform: capitalize;
  }
  label {
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
    span {
      color: red;
      padding-right: 5px;
      font-weight: 700;
      text-transform: capitalize;
    }
  }
  input {
    margin: 10px 0px;
    border: 1px solid;
    border-radius: 8px !important;
    background: #ffffff;
    padding: 10px 15px;
    text-transform: capitalize;
    &:focus {
      box-shadow: none !important;
    }
  }
  .mat-form-field-underline {
    display: none;
  }

  select {
    margin: 10px 0px;
    border: 1px solid;
    border-radius: 4px !important;
    background: #ffffff;
    padding: 7px 15px;
    text-transform: capitalize;
    &:focus {
      box-shadow: none !important;
    }
  }
  .form-group {
    .row {
      justify-content: space-between;
    }
  }
}

// OffCanvas Styling for OTP

.offcanvas-header {
  padding-top: 40px !important;
  font-size: 13px !important;
  color: $base-text-color;
  justify-content: start !important;
  .mat-icon {
    color: $orange_color;
    cursor: pointer;
  }
  .mb-no {
    color: $info-color;
  }
}
.offcanvas-body {
  text-align: center !important;
  p {
    color: $base-text-color;
  }
  .mobile-no {
    color: $info-color;
  }
  .otp_sent-verify {
    font-size: 15px;
  }
  .resend-otp {
    margin-top: 2rem;
    font-size: 11px;
    a {
      color: $info-color;
      cursor: pointer;
    }
  }
  .mat-error {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    .mat-icon {
      font-size: 11px;
    }
  }
  .button-orange {
    display: unset !important;
    .mat-icon {
      height: 19px !important;
    }
  }
}

/* Hide the up and down arrows for number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

// sidebar toggle code
.sidebar {
  transition: 0.25s !important;
}

// Styling for Mobile screens
@include devices(mobile) {
  .h1,
  .h2,
  .h3 {
    &:before {
      margin-top: 60px;
    }
  }
  .mat-dialog-container,
  .mat-dialog-content {
    overflow: scroll !important;
  }

  #landing-page .banner-top .owl-stage-outer img {
    height: 200px;
    object-fit: fill;
  }
  #landing-page .banner-top .owl-nav {
    top: 0;
  }
  #icon-listing .owl-item .icon-item {
    width: 100px !important;
    height: 100px !important;
    img {
      width: 30px !important;
      height: 30px !important;
    }
    .line-break {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  #landing-page #icon-listing .owl-nav .owl-prev {
    top: 36px;
  }
  #landing-page #icon-listing .owl-nav .owl-next {
    top: 36px;
  }
  #landing-page {
    .owl-item {
      img {
        height: 150px !important;
      }
      span {
        font-size: 12px !important;
        margin-top: 10px;
      }
    }
    .owl-nav {
      .owl-prev,
      .owl-next {
        width: 30px !important;
        height: 30px !important;
      }
    }
  }
  #landing-page #carousel-product-card .owl-nav {
    top: 80px;
  }
  #carousel-product-card,
  #view-product #similar-product-view {
    .owl-item {
      .mat-card {
        width: 100% !important;
      }
    }
  }
}
